import React from 'react'
import axios from 'axios'
import { Link, navigate } from 'gatsby'
import Container from '@material-ui/core/Container'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../components/footer/footer'
import barLogo from '../assets/images/bar-logo.png'
import { Paper, Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import useStyles from '../utils/styles'
import Layout from '../components/Layout'
import { serverURL } from '../utils/RestAPI'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLogin: true,
      email: '',
      login_message: ``,
    }
  }


  loadLoginMessage(login_message) {
    const token = localStorage.getItem('token');
    const beartoken = "Bearer " + token;
    const headers = { 
      'Authorization': beartoken
    };
    const LOGIN_MESSAGE_URL = `${serverURL}/api/domainMessageByName/${login_message}`;

    axios.get(LOGIN_MESSAGE_URL, { headers })
      .then(response => {
        const { domain_message } = response.data.data;
        this.setState({ login_message: domain_message || '' });
      })
      .catch(error => {
        console.log(error);
        this.setState({ login_message: '' });
      });
  }


  componentDidMount() {
    const { dispatch, userData, basicData } = this.props
    const domain = localStorage.getItem('domain')
    this.loadLoginMessage(domain);
  }

  onSignup = () => {}

  onSignin = () => {}

  handleChange = event => {
    this.setState({ email: event.target.value })
  }

  render() {
    const { userData, classes } = this.props
    const { login_message } = this.state;
    const displayMessage = login_message || "Welcome to Id Bio Card At Veritec Inc., we believe that our success is driven by the passion and dedication of our people. Our mission is to empower businesses and communities through cutting-edge technology, and we are thrilled to have you join us on this journey.";
  // Array containing section titles
 
    return (
      <Layout  menuIndex={this.props.menuIndex} loader={this.state.showLoader}>
        <Container maxWidth="md" className={classes.rootContainer}>
          <Paper className={classes.paper} elevation={0}>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img alt="" src={barLogo} width={'100%'} />
            </div>
            <div style={{ marginTop: 20 }}>
              <div className={classes.descScrollRoot}>
               {displayMessage}
              </div>
              <div style={{ marginTop: 20 }}>
                
                  <Button color="primary" style={{ textTransform: 'none',  }} onClick={()=>navigate('/about')}>
                    About
                  </Button>
                
                <Link to="/terms-conditions">
                  <Button
                    color="primary"
                    style={{ float: 'right', textTransform: 'none' }}
                  >
                    Terms and Conditions
                  </Button>
                </Link>
              </div>
            </div>
          </Paper>
        </Container>
        <Footer/>
      </Layout>
    )
  }
}

export default function(props) {
  const dispatch = useDispatch()
  const userData = useSelector(state => state.app.userData)
  const classes = useStyles()

  return (
    <Dashboard
      {...props}
      menuIndex={0}
      dispatch={dispatch}
      userData={userData}
      classes={classes}
    />
  )
}
